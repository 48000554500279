import React, { useEffect, useState, useMemo, ChangeEvent, useCallback } from "react";
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";
import PropertyCard from "../components/property/PropertyCard";
import { useInView } from "react-intersection-observer";
import "../App.css";
import { Filter, getProperties, Property } from "../utils/properties";
import useThemeStore from "../store/themeStore";
import { ThemeProps } from "../utils/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faCity, faEnvelope, faCopy } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import FeatureEnquiryModal from "./components/FeatureEnquiryModal";
import ReactDOM from 'react-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CustomCard } from "./components/FeaturedCardsCarousel";
import backgroundPattern from "../images/vesta/vesta-property-pattern-01-teal-bg.svg";
import ListView from '../components/property/ListView';
import ViewModeSelector from '../components/ViewModeSelector';
import DetailView from '../components/property/DetailView';
import useTokenStore from "../store/store";
import SearchComponent from './components/SearchComponent';
import useCardList from '../data/useCardList';

interface PropertyNavigationProps {
  currentPage: string;
}

interface NavItemProps {
  icon: any;
  text: string;
  isActive: boolean;
  linkTo: string;
  className?: string; // Allow className as an optional prop
}

const NavItem: React.FC<NavItemProps> = ({ icon, text, isActive, linkTo }) => (
  <NavLink 
    to={linkTo} 
    className={`flex flex-col items-center text-xs sm:text-sm md:text-base lg:text-sm xl:text-xs px-4 py-2 rounded-lg transition-colors duration-200 ease-in-out ${
      isActive ? 'text-white bg-blue-600' : 'text-gray-700 hover:bg-gray-200'
    }`}
  >
    <FontAwesomeIcon 
      icon={icon} 
      className="text-lg sm:text-xl md:text-2xl lg:text-xl xl:text-lg mb-1" 
    />
    <span>{text}</span>
  </NavLink>
);

const PropertyNavigation: React.FC<PropertyNavigationProps> = ({ currentPage }) => {
  return (
    <div className="max-w-7xl mx-auto my-8 pt-8 px-4">
      <nav className="flex justify-between sm:justify-start items-center gap-2 sm:gap-4 flex-wrap">
        <NavItem 
          icon={faMagnifyingGlass} 
          text="Find Property" 
          isActive={currentPage === 'find'}
          linkTo="/listing"
        />
        {/*<NavItem 
          icon={faHouse} 
          text="Sell Your Property" 
          isActive={currentPage === 'sell'}
          linkTo="/PropertyValuation"
        />*/}
        <NavItem 
          icon={faCity} 
          text="Explore Services" 
          isActive={currentPage === 'services'}
          linkTo="/Services"
        />
      </nav>
    </div>
  );
};

function PropertyListing() {
  const navigate = useNavigate();
  const location = useLocation();
  const getToken = useTokenStore((state) => state.token);
  const cardList = useCardList();

  // Parse URL parameters
  const params = new URLSearchParams(location.search);

  // Initialize state variables from URL parameters
  const [region, setRegion] = useState(params.get('region') || '');
  const [strategy, setStrategy] = useState(params.get('strategy') || '');
  const [propertyType, setPropertyType] = useState(params.get('propertyType') || '');
  const [minValue, set_minValue] = useState(params.get('minValue') ? Number(params.get('minValue')) : 0);
  const [maxValue, set_maxValue] = useState(params.get('maxValue') ? Number(params.get('maxValue')) : 1000000000);
  const [isReserved, setIsReserved] = useState(params.get('isReserved') === 'true');
  const [searchKeyword, setSearchKeyword] = useState(params.get('searchKeyword') || '');
  const [debouncedSearchKeyword, setDebouncedSearchKeyword] = useState(params.get('searchKeyword') || '');
  const [sortDate, setSortDate] = useState(params.get('sortDate') || 'Latest');
  const [sortPrice, setSortPrice] = useState(params.get('sortPrice') || 'By Price');
  const [sortYield, setSortYield] = useState(params.get('sortYield') || 'By Yield');
  const [sortDiscount, setSortDiscount] = useState(params.get('sortDiscount') || 'By Discount');
  const [activeSort, setActiveSort] = useState<string | null>(() => {
    if (params.get('sortDate') && params.get('sortDate') !== 'By Date') {
      return 'date';
    } else if (params.get('sortPrice') && params.get('sortPrice') !== 'By Price') {
      return 'price';
    } else if (params.get('sortYield') && params.get('sortYield') !== 'By Yield') {
      return 'yield';
    } else if (params.get('sortDiscount') && params.get('sortDiscount') !== 'By Discount') {
      return 'discount';
    } else {
      return 'date'; // default activeSort
    }
  });

  const [properties, setProperties] = useState<Property[]>([]);
  const [filterProperties, setFilterProperties] = useState<Filter | null>(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit] = useState(30);
  const [selectedProductId, selectedProductIdSet] = useState<string>("");
  const [viewMode, setViewMode] = useState('grid');
  const [sortConfig] = useState<{ key: keyof Property | null, direction: 'ascending' | 'descending' }>({ key: null, direction: 'ascending' });
  const [loadFilter] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const getThemes = useThemeStore((state) => state.theme as unknown as ThemeProps);

  useEffect(() => {
    const intendedPropertyId = sessionStorage.getItem('intendedPropertyId');
    const storedFilterState = sessionStorage.getItem('filterState');
  
    if (getToken && intendedPropertyId) {
      sessionStorage.removeItem('intendedPropertyId');
      navigate(`/listing/${intendedPropertyId}`);
    } else if (storedFilterState) {
      const filterState = JSON.parse(storedFilterState);
      setRegion(filterState.region || "");
      setStrategy(filterState.strategy || "");
      setPropertyType(filterState.propertyType || "");
      set_minValue(filterState.minValue || 0);
      set_maxValue(filterState.maxValue || 1000000000);
      setIsReserved(filterState.isReserved || false);
      setSortDate(filterState.sortDate || "Latest");
      setSortPrice(filterState.sortPrice || "By Price");
      setSortYield(filterState.sortYield || "By Yield");
      setSortDiscount(filterState.sortDiscount || "By Discount");
      setSearchKeyword(filterState.searchKeyword || "");
      setActiveSort(filterState.activeSort || "date");
  
      sessionStorage.removeItem('filterState');
    }
  }, [getToken, navigate]);

  const handleCardClick = (id: string) => {
    if (getToken) {
      navigate(`/listing/${id}`);
    } else {
      // Store the current filter state
      const filterState = {
        region,
        strategy,
        propertyType,
        minValue,
        maxValue,
        isReserved,
        sortDate,
        sortPrice,
        sortYield,
        sortDiscount,
        searchKeyword,
        activeSort
      };
      sessionStorage.setItem('filterState', JSON.stringify(filterState));
  
      // Navigate to login with redirectTo parameter
      navigate(`/login?redirectTo=/listing/${id}`);
    }
  };

  const [featuredCards, setFeaturedCards] = useState(cardList);

  const shuffleFeaturedCards = useCallback(() => {
    setFeaturedCards([...cardList].sort(() => Math.random() - 0.5));
  }, [cardList]);

  useEffect(() => {
    const interval = setInterval(shuffleFeaturedCards, 15000);
    return () => clearInterval(interval);
  }, [shuffleFeaturedCards]);

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState<Property | null>(null);
  const [animateIcons, setAnimateIcons] = useState(false);

  useEffect(() => {
    if (isShareModalOpen) {
      setAnimateIcons(true);
      setTimeout(() => setAnimateIcons(false), 800); // duration of pulse animation
    }
  }, [isShareModalOpen]);

  const openShareModal = (property: Property) => {
    setSelectedProperty(property);
    setIsShareModalOpen(true);
  };

  const closeShareModal = () => {
    setIsShareModalOpen(false);
    setSelectedProperty(null);
  };

  // Modal Links
  const generatePropertyLink = (propertyId: string) => {
    const baseUrl = window.location.origin; 
    return `${baseUrl}/listing/${propertyId}`;
  };

  const [copyFeedback, setCopyFeedback] = useState('Copy Link');

  const handleCopyLink = () => {
    const link = generatePropertyLink(selectedProperty!._id);
    navigator.clipboard.writeText(link);
    setCopyFeedback('Link Copied');
    setTimeout(() => {
      setCopyFeedback('Copy Link');
      closeShareModal();
    }, 2000);
  };

  const handleWhatsAppShare = () => {
    const link = generatePropertyLink(selectedProperty!._id);
    window.open(`https://wa.me/?text=${encodeURIComponent(link)}`, '_blank');
    closeShareModal();
  };

  const handleEmailShare = () => {
    const link = generatePropertyLink(selectedProperty!._id);
    window.location.href = `mailto:?subject=Check out this property&body=${encodeURIComponent(link)}`;
    closeShareModal();
  };

  const handleRegionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedRegion = e.target.value;
    setRegion(selectedRegion);
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(e.target.value);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchKeyword(searchKeyword);
    }, 300);

    return () => clearTimeout(timer);
  }, [searchKeyword]);

  // Update URL when filters change
  useEffect(() => {
    const params = new URLSearchParams();

    if (region) params.set('region', region);
    if (strategy) params.set('strategy', strategy);
    if (propertyType) params.set('propertyType', propertyType);
    if (minValue !== 0) params.set('minValue', minValue.toString());
    if (maxValue !== 1000000000) params.set('maxValue', maxValue.toString());
    if (isReserved) params.set('isReserved', isReserved.toString());
    if (searchKeyword) params.set('searchKeyword', searchKeyword);
    if (sortDate && sortDate !== "By Date") params.set('sortDate', sortDate);
    if (sortPrice && sortPrice !== "By Price") params.set('sortPrice', sortPrice);
    if (sortYield && sortYield !== "By Yield") params.set('sortYield', sortYield);
    if (sortDiscount && sortDiscount !== "By Discount") params.set('sortDiscount', sortDiscount);

    // Update the URL without reloading the page
    const newUrl = `${location.pathname}?${params.toString()}`;
    if (newUrl !== `${location.pathname}${location.search}`) {
      navigate(newUrl, { replace: true });
    }
  }, [
    region,
    strategy,
    propertyType,
    minValue,
    maxValue,
    isReserved,
    searchKeyword,
    sortDate,
    sortPrice,
    sortYield,
    sortDiscount,
    navigate,
    location.pathname,
    location.search,
  ]);

  // Fetch properties when filters change
  useEffect(() => {
    const fetchProperties = async () => {
      setLoading(true);
      const filter = {
        ...(region && { region }),
        ...(propertyType && { propertyType }),
        ...(strategy && { strategy }),
        ...(minValue !== 0 && { minPrice: minValue }),
        ...(maxValue !== 1000000000 && { maxPrice: maxValue }),
        ...(debouncedSearchKeyword && { searchKeyword: debouncedSearchKeyword }),
        ...(sortDate !== "By Date" && { sortDate }),
        ...(sortPrice !== "By Price" && { sortPrice }),
        ...(sortYield !== "By Yield" && { sortYield }),
        ...(sortDiscount !== "By Discount" && { sortDiscount }),
        isReserved: isReserved,
      };
  
      const pr = await getProperties({ ...filter, page: 1, limit: limit });
      
      // Filter out reserved properties if isReserved is false
      const filteredProperties = isReserved ? pr.results : pr.results.filter((prop: Property) => !prop.reserved);
      
      setProperties(filteredProperties);
      setFilterProperties(pr.filter);
      setLoading(false);
      setPage(1);
      setHasMore(pr.pagination.next !== null);
    };
  
    fetchProperties();
  }, [
    region,
    propertyType,
    strategy,
    minValue,
    maxValue,
    debouncedSearchKeyword,
    sortDate,
    sortPrice,
    sortYield,
    sortDiscount,
    isReserved,
    limit,
    location.search,
  ]);

  const viewMore = useCallback(async () => {
    if (properties && properties.length > 0 && !loadingMore && hasMore) {
      setLoadingMore(true);
      const filter = {
        ...(region && { region }),
        ...(propertyType && { propertyType }),
        ...(strategy && { strategy }),
        ...(minValue !== 0 && { minPrice: minValue }),
        ...(maxValue !== 1000000000 && { maxPrice: maxValue }),
        ...(searchKeyword && { searchKeyword }),
        ...(sortDate !== "By Date" && { sortDate }),
        ...(sortPrice !== "By Price" && { sortPrice }),
        ...(sortYield !== "By Yield" && { sortYield }),
        ...(sortDiscount !== "By Discount" && { sortDiscount }),
        isReserved,
      };
  
      const pr = await getProperties({ ...filter, page: page + 1, limit: limit });
      if (pr.results && pr.results.length > 0) {
        setProperties(prevProperties => [...prevProperties, ...pr.results]);
        setPage(prevPage => prevPage + 1);
        setHasMore(pr.pagination.next !== null);
      } else {
        setHasMore(false);
      }
      setLoadingMore(false);
    }
  }, [
    properties,
    loadingMore,
    hasMore,
    region,
    propertyType,
    strategy,
    minValue,
    maxValue,
    searchKeyword,
    sortDate,
    sortPrice,
    sortYield,
    sortDiscount,
    isReserved,
    page,
    limit,
  ]);
  

  const sortPriceAction = () => {
    setSortPrice("Lowest");
    setSortDate("By Date");
    setSortYield("By Yield");
    setSortDiscount("By Discount");
    setActiveSort("price");
  };

  const sortDateAction = () => {
    setSortDate("Latest");
    setSortPrice("By Price");
    setSortYield("By Yield");
    setSortDiscount("By Discount");
    setActiveSort("date");
  };

  const sortYieldAction = () => {
    setSortYield("Highest");
    setSortDate("By Date");
    setSortPrice("By Price");
    setSortDiscount("By Discount");
    setActiveSort("yield");
  };

  const sortDiscountAction = () => {
    setSortDiscount("Highest");
    setSortDate("By Date");
    setSortYield("By Yield");
    setSortPrice("By Price");
    setActiveSort("discount");
  };

  const clearFilters = async () => {
    const scrollPosition = window.pageYOffset;
  
    setRegion("");
    setStrategy("");
    setPropertyType("");
    set_minValue(filterProperties?.minPrice ?? 0);
    set_maxValue(filterProperties?.maxPrice ?? 1000000000);
    setSortDate("Latest");
    setSortPrice("By Price");
    setSortYield("By Yield");
    setSortDiscount("By Discount");
    setIsReserved(false);  // Ensure this is set to false
    
    // Clear search states
    setSearchKeyword("");
    setDebouncedSearchKeyword("");
  
    // Set activeSort to "date" to highlight the "Newest Date" button
    setActiveSort("date");
  
    setLoading(true);
    const pr = await getProperties({ page: 1, limit: limit, sortDate: "Latest" });
    
    // Filter out reserved properties after fetching
    const filteredProperties = pr.results.filter((prop: Property) => !prop.reserved);
    
    setProperties(filteredProperties);
    setFilterProperties(pr.filter);
    setLoading(false);
    setPage(1);
    setHasMore(pr.pagination.next !== null);
  
    setTimeout(() => {
      window.scrollTo(0, scrollPosition);
    }, 0);
  };

  const [viewMoreButtonRef, inView] = useInView({});

  useEffect(() => {
    if (inView) {
      viewMore();
    }
  }, [inView, viewMore]);

  interface PropsLoading {
    color: string;
  }
  
  const LoadingDiv = ({ color }: PropsLoading) => {
    return (
      <div className="h-10 w-10">
        <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
          <path fill={color} d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
            <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
          </path>
        </svg>
      </div>
    );
  };
   
  const sortedProperties = useMemo(() => {
    let sortableItems = [...properties];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        const aValue = a[sortConfig.key as keyof Property];
        const bValue = b[sortConfig.key as keyof Property];

        if (aValue === null || bValue === null || aValue === undefined || bValue === undefined) {
          return 0;
        }

        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return sortConfig.direction === 'ascending'
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        }

        if (typeof aValue === 'number' && typeof bValue === 'number') {
          return sortConfig.direction === 'ascending'
            ? aValue - bValue
            : bValue - aValue;
        }

        return 0;
      });
    }
    return sortableItems;
  }, [properties, sortConfig]);

  function requestSort(key: keyof Property): void {
    throw new Error("Function not implemented.");
  }

  // Helper function to clean and deduplicate options
  const cleanOptions = (options: string[]) => {
    const uniqueOptions = new Set<string>();
    options.forEach(option => {
      if (option.trim() !== "" && option.toLowerCase() !== "block of three flats") {
        uniqueOptions.add(option.trim().toLowerCase());
      }
    });

    return Array.from(uniqueOptions).map(option => {
      if (option === "hmo") {
        return "HMO";
      }
      if (option === "semi-detached" || option === "semi-detached house") {
        return "Semi-Detached House";
      }
      if (option === "terraced" || option === "terraced house") {
        return "Terraced House";
      }
      return option.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }).filter((value, index, self) => self.indexOf(value) === index); // Ensuring unique options
  };

  // White
  const isWhiteOrShade = (color: string) => {
    // Check if the color is exactly white
    if (color.toLowerCase() === "white" || color === "rgba(255,255,255,1)") return true;
  
    // Check if the color is a shade of white (hex values like #fff or #ffffff)
    if (/^#fff[a-f0-9]{0,3}$/i.test(color)) return true;
  
    // Check if the color is a shade of white (rgb or rgba format)
    const rgba = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*([\d.]+)?\)/);
    if (rgba) {
      const [, r, g, b] = rgba.map(Number);
      // If R, G, and B are close to 255, consider it a shade of white
      return r >= 240 && g >= 240 && b >= 240;
    }
  
    return false;
  };
  
  // Usage in JSX
  const borderColor = getThemes && isWhiteOrShade(getThemes.color1) ? getThemes.color2 : getThemes.color1;
  


  return (
    <div className="PropertiesListing pt-20">
      <Helmet>
        <title>Property Listing</title>
      </Helmet>
      <PropertyNavigation currentPage="find" />
      <div className="pb-8">
        <div className="border max-w-7xl m-auto p-5 rounded-xl shadow-md">
          <div className="grid lg:grid-cols-4 grid-cols-1 lg:gap-x-2 gap-1 max-w-7xl m-auto justify-center items-center lg:px-0 px-5">
            {/* Region Select */}
            <div className="px-2">
              <label
                htmlFor="hs-select-label-region"
                className="block text-base font-normal mb-2 text-primarycolor"
                >
                Region
              </label>
              <div className="relative">
                <select
                  id="hs-select-label-region"
                  className="py-3 px-4 pr-9 block w-full border-primarycolor border-2 rounded-md text-sm bg-white-f2f9fa appearance-none"
                  style={{ borderColor }}
                  value={region}
                  onChange={handleRegionChange}
                >
                  <option value="">Select</option>
                  {properties &&
                    filterProperties?.regions.map((ele, index) => (
                      <option key={index} value={ele}>
                        {ele}
                      </option>
                    ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>

            {/* Strategy Select */}
            <div className="px-2">
              <label
                htmlFor="hs-select-label-strategy"
                className="block text-base font-normal mb-2 text-primarycolor"
                >
                Strategy
              </label>
              <div className="relative">
                <select
                  id="hs-select-label-strategy"
                  className="py-3 px-4 pr-9 block w-full border-primarycolor border-2 rounded-md text-sm bg-white-f2f9fa appearance-none"
                  style={{ borderColor }}
                  value={strategy}
                  onChange={(e) => {
                    setStrategy(e.target.value);
                  }}>
                  <option value="">Select</option>
                  {properties &&
                    cleanOptions(filterProperties?.strategy ?? []).map((ele, index) => (
                      <option key={index} value={ele}>
                        {ele}
                      </option>
                    ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>

            {/* Property Type Select */}
            <div className="px-2">
              <label
                htmlFor="hs-select-label-property-type"
                className="block text-base font-normal mb-2 text-primarycolor"
                >
                Property Type
              </label>
              <div className="relative">
                <select
                  id="hs-select-label-property-type"
                  className="py-3 px-4 pr-9 block w-full border-primarycolor border-2 rounded-md text-sm bg-white-f2f9fa appearance-none"
                  style={{ borderColor }}
                  value={propertyType}
                  onChange={(e) => {
                    setPropertyType(e.target.value);
                  }}>
                  <option value="">Select</option>
                  {properties &&
                    cleanOptions(filterProperties?.propertyType ?? []).map((ele, index) => (
                      <option key={index} value={ele}>
                        {ele}
                      </option>
                    ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>

            {/* Price Range Select */}
            <div className="px-2">
              <label
                htmlFor="hs-select-label-price"
                className="block text-base font-normal mb-2 text-primarycolor"
                >
                Price
              </label>
              <div className="relative">
                <select
                  id="hs-select-label-price"
                  className="py-3 px-4 pr-9 block w-full border-primarycolor border-2 rounded-md text-sm bg-white-f2f9fa appearance-none"
                  style={{ borderColor }}
                  value={`${minValue}-${maxValue}`}
                  onChange={(e) => {
                    const [min, max] = e.target.value.split('-').map(Number);
                    set_minValue(min);
                    set_maxValue(max);
                  }}>
                  <option value="0-999999999">Any</option>
                  <option value="0-125000">Up to £125,000</option>
                  <option value="125000-250000">£125,000 - £250,000</option>
                  <option value="250000-500000">£250,000 - £500,000</option>
                  <option value="500000-1000000">£500,000 - £1,000,000</option>
                  <option value="1000000-5000000">£1,000,000 - £5,000,000</option>
                  <option value="5000000-999999999">£5,000,000+</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>


          <div className="mt-5 flex flex-col md:flex-row items-center justify-between px-2">
            {/* Sorting Section */}
            <div className="grid grid-cols-2 md:grid-cols-6 gap-4 items-center">
                <h3 className="text-gray-700 font-medium col-span-2 md:col-span-1">Sort By:</h3>

                {/* Date Sort */}
                <button
                    onClick={sortDateAction}
                    className="py-2 px-2 inline-flex justify-center items-center gap-2 rounded-md border font-medium shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm"
                    style={{
                        backgroundColor: activeSort === "date" ? getThemes.color2 : "white",
                        borderColor: activeSort === "date" ? getThemes.color2 : "gray",
                        color: activeSort === "date" ? "white" : "black",
                    }}
                >
                    Newest Date
                </button>

                {/* Price Sort */}
                <button
                    onClick={sortPriceAction}
                    className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm"
                    style={{
                        backgroundColor: activeSort === "price" ? getThemes.color2 : "white",
                        borderColor: activeSort === "price" ? getThemes.color2 : "gray",
                        color: activeSort === "price" ? "white" : "black",
                    }}
                >
                    Lowest Price
                </button>

                {/* Yield Sort */}
                <button
                    onClick={sortYieldAction}
                    className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm"
                    style={{
                        backgroundColor: activeSort === "yield" ? getThemes.color2 : "white",
                        borderColor: activeSort === "yield" ? getThemes.color2 : "gray",
                        color: activeSort === "yield" ? "white" : "black",
                    }}
                >
                    Highest Yield
                </button>

                {/* Discount Sort */}
                <button
                    onClick={sortDiscountAction}
                    className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm"
                    style={{
                        backgroundColor: activeSort === "discount" ? getThemes.color2 : "white",
                        borderColor: activeSort === "discount" ? getThemes.color2 : "gray",
                        color: activeSort === "discount" ? "white" : "black",
                    }}
                >
                    Highest Discount
                </button>

            {/* Clear Filters Button */}
                <button
                    onClick={clearFilters}
                    className={`py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm
                    ${!activeSort ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={!activeSort}
                >
                    Clear Filters
                </button>
            </div>

            {/* Search Bar */}
            <div className="px-2 pt-5 sm:pt-0">
              <SearchComponent
                searchKeyword={searchKeyword}
                handleSearchInputChange={handleSearchInputChange}
                getThemes={getThemes}
              />
            </div>
          </div>

        </div>
      </div>
      {!loading && (
        <div className="flex flex-wrap justify-between m-auto max-w-7xl mb-5 pl-2">
          <div className="flex flex-wrap justify-end  items-center gap-3">
          <div className="flex items-center">
            <input
              onChange={async (e) => {
                const isChecked = e.target.checked;
                setIsReserved(isChecked);
              }}
              checked={isReserved}
              id="disabled-checked-checkbox"
              type="checkbox"
              className="cursor-pointer w-5 h-5 rounded focus:ring-2"
              style={{
                appearance: 'none', // Remove default checkbox style
                backgroundColor: isReserved ? getThemes.color2 : "white", // Set background color based on checked state
                borderColor: 'black', // Always have a black border
                borderWidth: '2px',
                borderStyle: 'solid',
                width: '20px', // Increase width
                height: '20px', // Increase height
                display: 'inline-block',
                borderRadius: '4px',
                position: 'relative',
              }}
            />
            <label
              htmlFor="disabled-checked-checkbox"
              className="ml-2 text-sm font-medium cursor-pointer"
              style={{
                color: "black", // Label color set to black
              }}
            >
              Show Reserved Stock
            </label>

            <style>
              {`
                input[type="checkbox"]:checked::before {
                  content: '\\2714'; /* Unicode for checkmark */
                  display: block;
                  color: white;
                  font-weight: bold;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%); /* Center the checkmark */
                  font-size: 14px; /* Increase font size for larger tick */
                }
              `}
            </style>
          </div>
          </div>
        </div>
      )}
      <div className="max-w-7xl m-auto pb-10" style={{ overflowX: "hidden" }}>
        <FeatureEnquiryModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} productId={selectedProductId} />
        {!loadFilter && (
          <>
            <div className="flex justify-between items-center mb-4 pl-2">
              <h2 className="text-2xl font-bold">Off Market Property Listings</h2>
              <ViewModeSelector viewMode={viewMode} setViewMode={setViewMode} getThemes={getThemes} />
            </div>
            {viewMode === 'grid' && (
              <div className="grid lg:grid-cols-3 grid-cols-1 w-full m-auto gap-3 lg:px-0 px-3">
                {sortedProperties.map((ele, index) => (
                  <React.Fragment key={index}>
                    <PropertyCard
                      key={index}
                      discount={ele.discountToMarket as unknown as string}
                      discountLabel={ele.discountLabel}
                      id={ele._id}
                      available={!ele.reserved}
                      image={ele.images && ele.images.length > 0 ? ele.images[0].url : ""}
                      streetName={ele.propertyOf}
                      price={ele.price as unknown as string}
                      bedrooms={ele.bedrooms as unknown as string}
                      value={ele.estimatedValue as unknown as string}
                      rent={ele.averageRent as unknown as string}
                      netCashFlow={ele.netCashflow as unknown as string}
                      grossYield={ele.rentGrossYield as unknown as string}
                      strategy={ele.strategy as unknown as string}
                      occupancy={ele.occupancy}
                      tenureType={ele.tenureType}
                      floorsqft={ele.floorsqft}
                      region={(ele as Property).region}
                      investment={ele.investment as unknown as string}
                      onShareClick={() => openShareModal(ele)}
                      onClick={() => handleCardClick(ele._id)}
                    />
                    {(index + 1) % 10 === 0 && featuredCards[Math.floor(index / 10) % featuredCards.length] && (
                      <div
                        key={`featured-${index}`}
                        className="shadow-md rounded-lg overflow-hidden"
                        style={{
                          backgroundImage: `url(${backgroundPattern})`,
                          backgroundColor: '',
                          backgroundRepeat: 'repeat',
                          backgroundSize: '60px 60px',
                        }}
                      >
                        <CustomCard
                        isFirst={false} isLast={false} {...featuredCards[Math.floor(index / 10) % featuredCards.length]}
                        onOpenModal={(productId) => {
                          selectedProductIdSet(productId);
                          setIsModalOpen(true);
                        } }                        />
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            )}
            {viewMode === 'list' && (
              <ListView 
                properties={sortedProperties} 
                onRequestSort={requestSort} 
                onShareClick={openShareModal} 
              />
            )}
            {viewMode === 'detail' && (
              <DetailView 
                properties={sortedProperties}
                onShareClick={openShareModal}
                setIsModalOpen={setIsModalOpen}  // Pass the actual modal state function here
                selectedProductIdSet={selectedProductIdSet}  // Pass the actual product ID setter function here
              />
            )}
            {!loading && properties?.length === 0 && (
            <div className="flex w-full justify-center items-center mt-10">
              <h1 className="text-center">No Properties Found</h1>
            </div>
          )}

          {loading && (
            <div className="h-screen flex top-[50%] bottom-0 m-auto justify-center items-center">
              <LoadingDiv color={getThemes && getThemes.color1 === "rgba(255,255,255,1)" ? "rgba(180,180,180,1)" : getThemes.color1} />
            </div>
          )}

          </>
        )}
        {loading && (
          <div className="h-screen flex top-[50%] bottom-0 m-auto justify-center items-center">
            <LoadingDiv color={getThemes && getThemes.color1 === "rgba(255,255,255,1)" ? "rgba(180,180,180,1)" : getThemes.color1} />
          </div>
        )}
        {loadFilter && (
          <div className="flex w-full justify-center items-center  mt-10">
            <LoadingDiv color={getThemes && getThemes.color1 === "rgba(255,255,255,1)" ? "rgba(180,180,180,1)" : getThemes.color1} />
          </div>
        )}
        {!loading && !loadFilter && hasMore && (
          <div ref={viewMoreButtonRef}>
            <div className="w-full justify-center items-center">
              <button className="">{loadingMore && <LoadingDiv color={getThemes && getThemes.color1 === "rgba(255,255,255,1)" ? "rgba(180,180,180,1)" : getThemes.color1} />}</button>
            </div>
          </div>
        )}
      </div>
      {isShareModalOpen && selectedProperty && ReactDOM.createPortal(
        <div className="fixed inset-0 flex items-center justify-center z-[9999]" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="bg-white rounded-lg p-6 w-96 relative" style={{ backgroundColor: 'white' }}>
            <style>
              {`
                @keyframes pulse {
                  0%, 100% { transform: scale(1); }
                  30% { transform: scale(1.2); }
                }
                .pulse {
                  animation: pulse 1s infinite;
                }
              `}
            </style>
            <span 
              onClick={closeShareModal}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                fontSize: "20px",
                fontWeight: "bold",
                transition: "transform 0.2s",
                display: "inline-block",
              }}
              onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.3)'}
              onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
            >
              &times;
            </span>
            <h3 className="text-2xl font-bold mb-4 text-center">Share Property</h3>
            <div className="flex items-center mb-4">
              {selectedProperty.images && selectedProperty.images.length > 0 && (
                <LazyLoadImage
                  src={selectedProperty.images[0].url}
                  width={120}
                  height={60}
                  className="rounded mr-4 object-cover"
                  alt="Property Thumbnail"
                  style={{ width: '140px', height: '80px', objectFit: 'cover' }}
                />
              )}
              <div>
                <h4 className="font-medium">{selectedProperty.propertyOf}</h4>
                <p className="text-gray-600">{selectedProperty.fullAddress}</p>
              </div>
            </div>
            <p className="mb-4 text-center">
              You can share the following link for anyone to view this property
            </p>
            <div className="flex justify-around">
            <div className="flex flex-col items-center">
              <button
                className={`focus:outline-none transition duration-300 ease-in-out transform hover:scale-110 ${animateIcons ? 'pulse' : ''}`}
                onClick={handleCopyLink}
              >
                <FontAwesomeIcon icon={faCopy} size="1x" className="mb-2" style={{ fontSize: '1.5rem' }} />
              </button>
              <span style={{ fontSize: '0.7rem' }}>{copyFeedback}</span>
            </div>

            <div className="flex flex-col items-center">
              <button
                className={`focus:outline-none transition duration-300 ease-in-out transform hover:scale-110 ${animateIcons ? 'pulse' : ''}`}
                onClick={handleWhatsAppShare}
                style={{ 
                  width: '2rem', 
                  height: '2rem', 
                  paddingTop: "2px",
                  backgroundColor: '#25D366', 
                  borderRadius: '60%', 
                  justifyContent: 'center', 
                  alignItems: 'center' 
                }}
              >
                <FontAwesomeIcon icon={faWhatsapp} style={{ fontSize: '1.3rem', color: 'white' }} />
              </button>
              <span style={{ fontSize: '0.7rem' }}>Whatsapp</span>
            </div>

            <div className="flex flex-col items-center">
              <button
                className={`focus:outline-none transition duration-300 ease-in-out transform hover:scale-110 ${animateIcons ? 'pulse' : ''}`}
                onClick={handleEmailShare}
              >
                <FontAwesomeIcon icon={faEnvelope} size="1x" className="mb-2" style={{ fontSize: '1.5rem' }} />
              </button>
              <span style={{ fontSize: '0.7rem' }}>Email</span>
            </div>
          </div>
          </div>
        </div>,
        document.body
      )}
    </div>
  );
}

export default PropertyListing;
